import React, { ReactNode } from 'react'
import classnames from 'classnames'
import { Link } from '../../routing/Router'

interface LinkWithIconProps {
  text: string
  target: string
  arrowLeft?: boolean
  arrowRight?: boolean
  icon?: string | ReactNode
  iconPosition?: 'left' | 'right'
  theme?: 'plain' | 'underline'
  useRouter?: boolean
  openNewWindow?: boolean
  analyticId?: string
  onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void
}

const LinkWithIcon: React.FC<LinkWithIconProps> = ({
  text,
  arrowLeft,
  arrowRight,
  icon,
  iconPosition = 'left',
  target,
  theme = 'underline',
  useRouter = false,
  openNewWindow,
  analyticId,
  onClick
}) => {
  const iconLeft = arrowLeft || (icon && iconPosition === 'left')
  const iconRight = arrowRight || (icon && iconPosition === 'right')
  const iconElement = typeof icon === 'string' ? <i className={icon}></i> : icon
  const classes = classnames('link-with-icon', theme === 'plain' && 'link-with-icon-plain')
  const tags = {
    router: {
      tag: Link,
    },
    anchor: {
      tag: 'a' as keyof JSX.IntrinsicElements,
    },
  }
  const tagKey = useRouter ? 'router' : 'anchor'
  const Element = tags[tagKey].tag

  return (
    <Element
      className={classes}
      to={target}
      href={target}
      target={openNewWindow ? '_blank' : undefined}
      data-analytic-id={analyticId}
      onClick={onClick}
    >
      {iconLeft && (
        <div className='link-with-icon-left'>
          {arrowLeft && <i className='fal fa-long-arrow-left'></i>}
          {iconPosition === 'left' && iconElement}
        </div>
      )}
      <span className='link-with-icon-text'>{text}</span>
      {iconRight && (
        <div className='link-with-icon-right'>
          {arrowRight && <i className='fal fa-long-arrow-right'></i>}
          {iconPosition === 'right' && iconElement}
        </div>
      )}
    </Element>
  )
}

export default LinkWithIcon
